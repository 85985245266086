<template>
  <div class="w-2/12" />
  <div class="flex items-center justify-center w-8/12">
    <InlineSvg v-if="isShowLogo" :src="IrisLogoStandart" width="100" height="40" />
  </div>
  <UserAction class="w-2/12" />
</template>

<script>
import IrisLogoStandart from '@/assets/svg/iris-logo-standard.svg'
// import BackAdvisor from '../BackAdvisor.vue'
import UserAction from '@/components/UserAction.vue'
import { useRoute } from 'vue-router'
import { computed } from 'vue'

export default {
  name: 'LeadClientHeader',
  components: {
    UserAction,
    // BackAdvisor
  },
  setup() {
    const route = useRoute()

    const isShowLogo = computed(() => {
      return route.name !== 'lead/dashboard'
    })
    const storedData = sessionStorage.getItem('role')
    const parsedData = JSON.parse(storedData)
    const role = parsedData.globalComponents.role
    const storedTRCMData = sessionStorage.getItem('TRCMData')
    const parsedTRCMData = JSON.parse(storedTRCMData)
    const email = parsedTRCMData.globalComponents.TRCMData.email
    const username = email.split('@')[0]

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'user_data_push',
      iris_user: username,
      iris_user_type: role,
    })
    return {
      isShowLogo,
      IrisLogoStandart,
    }
  },
}
</script>
